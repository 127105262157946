exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-creative-agency-js": () => import("./../../../src/pages/creative-agency.js" /* webpackChunkName: "component---src-pages-creative-agency-js" */),
  "component---src-pages-cryptocurrency-js": () => import("./../../../src/pages/cryptocurrency.js" /* webpackChunkName: "component---src-pages-cryptocurrency-js" */),
  "component---src-pages-currency-exchange-js": () => import("./../../../src/pages/currency-exchange.js" /* webpackChunkName: "component---src-pages-currency-exchange-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nft-js": () => import("./../../../src/pages/nft.js" /* webpackChunkName: "component---src-pages-nft-js" */),
  "component---src-pages-photography-portfolio-js": () => import("./../../../src/pages/photography-portfolio.js" /* webpackChunkName: "component---src-pages-photography-portfolio-js" */),
  "component---src-pages-political-campaign-js": () => import("./../../../src/pages/political-campaign.js" /* webpackChunkName: "component---src-pages-political-campaign-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-project-details-js": () => import("./../../../src/pages/project-details.js" /* webpackChunkName: "component---src-pages-project-details-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-saas-software-js": () => import("./../../../src/pages/saas-software.js" /* webpackChunkName: "component---src-pages-saas-software-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

